
import AppName from '@/components/mixin/AppName.vue';
import { outdatedFunctionality } from '@/store/Globals';
import { Product } from '@/store/models/Product';
import { formatHours } from '@/utils/DateUtils';
import { formatMilkScoreDisplay } from '@/utils/formatters/DisplayValueFormatters';
import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';
import RepairIcon from '../common/RepairIcon.vue';
import ConstantsMixin from '../mixin/Constants.vue';
import {
  complianceMethodLabel,
  getAgeThresholdHrs,
  getComplianceScore,
  getMethod
} from '@/utils/MilkScoreUtils';
import { formatMilkScoreColour } from '@/utils/TableFormatters';
import { isWarningMilkAge } from '@/utils/AlertUtils';
import { getImage } from '@/utils/SvgUtils';

@Component({
  components: { RepairIcon },
  methods: {
    formatMilkScoreDisplay,
    formatHours,
    getComplianceScore,
    formatMilkScoreColour,
    complianceMethodLabel,
    isWarningMilkAge,
    getAgeThresholdHrs,
    getMethod,
    getImage
  }
})
export default class WarningsIconsGroup extends mixins(
  AppName,
  ConstantsMixin
) {
  @Prop() item!: Product;
  @Prop() entity!: any;

  get outdatedVisible() {
    return outdatedFunctionality.visible;
  }

  get milkScore() {
    return getComplianceScore(
      this.entity.metrics.milkScores,
      this.entity.entityCustomSettingsData
    );
  }
}
