
import { Product } from '@/store/models/Product';
import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';
import AppName from '../mixin/AppName.vue';
import ConstantsMixin from '../mixin/Constants.vue';
import ActionAlertModal from '../modal/ActionAlertModal.vue';
import EditCommentModal from '../modal/EditCommentModal.vue';

@Component({
  components: {
    ActionAlertModal,
    EditCommentModal
  }
})
export default class Comment extends mixins(ConstantsMixin, AppName) {
  @Prop() item!: Product;
  hovering = false;

  public alertsUpdated() {
    this.$emit('alertsUpdated');
  }

  public openActionModal() {
    this.$root.$emit(
      'bv::show::modal',
      `${this.item.productId}_${this.item.entityId}actionAlertModal`,
      '#btnShow'
    );
  }

  public openEditModal() {
    this.$root.$emit(
      'bv::show::modal',
      `${this.item.productId}_${this.item.entityId}editCommentModal`,
      '#btnShow'
    );
  }
}
