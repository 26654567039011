
import FixedProgressBar from '@/components/items/FixedProgressBar.vue';
import TestComponent from '@/components/items/TestComponent.vue';
import { MilkMetrics, Product } from '@/store/models/Product';
import SortingInfo from '@/store/models/SortingInfo';
import { TableColumnFormatters } from '@/store/models/TableColumn';
import { getPageName, isPage } from '@/utils/PageUtils';
import { mixins } from 'vue-class-component';
import { Component, Prop, Watch } from 'vue-property-decorator';
import NestedEntity from '../common/NestedEntity.vue';
import RepairIcon from '../common/RepairIcon.vue';
import RouteCheckbox from '../common/RouteCheckbox.vue';
import SiteNameField from '../common/SiteNameField.vue';
import StatusBadge from '../common/StatusBadge.vue';
import TemperatureIcon from '../common/TemperatureIcon.vue';
import VolumeCapacityField from '../common/VolumeCapacityField.vue';
import VolumePercentBar from '../common/VolumePercentBar.vue';
import Comment from '../common/Comment.vue';
import WarningsIconsGroup from '../groups/WarningsIconsGroup.vue';
import AppName from '../mixin/AppName.vue';
import ConstantsMixin from '../mixin/Constants.vue';
import draggable from 'vuedraggable';
import DataTableColumn from '@/store/models/DataTableColumn';
import EditTableColumnVisibility from '../common/EditTableColumnVisibility.vue';
import LastMilkingDisplay from '@/components/common/LastMilkingDisplay.vue';
import Collapsible from '@/components/common/Collapsible.vue';
import { trackEvent } from '@/services/Mixpanel';

@Component({
  components: {
    draggable,
    FixedProgressBar,
    TestComponent,
    NestedEntity,
    WarningsIconsGroup,
    VolumePercentBar,
    VolumeCapacityField,
    RouteCheckbox,
    SiteNameField,
    StatusBadge,
    TemperatureIcon,
    RepairIcon,
    Comment,
    EditTableColumnVisibility,
    LastMilkingDisplay,
    Collapsible
  }
})
export default class Datatable extends mixins(AppName, ConstantsMixin) {
  @Prop() columns!: DataTableColumn[];
  @Prop() products!: Product[];
  @Prop() active!: string;
  @Prop() isParentHeader!: boolean;
  @Prop() collapsible!: boolean;
  @Prop() loading!: boolean;
  @Prop({ default: () => ({ sortBy: null, increasing: true }) })
  sortInfo!: SortingInfo;
  public tableWidth = 1400;
  public headers: string[] = [];

  mounted() {
    this.alterWidthOfTable();
    this.headers = this.columns.map(column => column.name);
  }

  @Watch('columns')
  columnsUpdated() {
    this.alterWidthOfTable();

    this.headers = this.columns.map(column => column.name);
  }

  public columnOrderingChanged(event: any) {
    const columns = this.columns.sort(
      (columnA: DataTableColumn, columnB: DataTableColumn) => {
        return (
          this.headers.indexOf(columnA.name) -
          this.headers.indexOf(columnB.name)
        );
      }
    );
    trackEvent(
      `User reordered columns on ${this.getAppName(false)} ${getPageName()}`,
      {
        columns: JSON.stringify(columns)
      }
    );
    this.$root.$emit('columnOrderChanged', event.moved, columns);
  }

  public getNestedChildren(item: Product) {
    if (this.isParentHeader) {
      //The parent row is considered a header row so all entities need to be returned
      return item.entities;
    } else {
      //The parent row is not a header so it was considered the first row of entities
      //Need to excluded the first entities for the nested children
      return item.entities?.slice(1, item.entities?.length);
    }
  }

  public alterWidthOfTable() {
    if (this.columns.length != 0) {
      const visibleDefault = this.columns.filter(column => {
        return column.visible;
      });
      let width = 0;
      visibleDefault.forEach(defaultCol => (width += defaultCol.width ?? 0));
      this.tableWidth = width + 60;
    }
  }

  public selectEntity(entityIndex: number) {
    this.$emit('entity-selected', entityIndex);
  }

  public selectRow(product: Product, entityIndex: number) {
    this.$emit('row-selected', product, entityIndex);
  }

  public getBackgroundColour(item: Product) {
    const isActive = this.isParentHeader
      ? this.isParentSelected(item.productId)
      : item.productId + '/0' === this.active;
    const isActionedAlert = isPage('alerts') && item.entityComments;
    if (isActive && isActionedAlert) {
      return 'bg-grey';
    } else if (isActive || isActionedAlert) {
      return 'bg-lightGrey';
    } else {
      return 'bg-white';
    }
  }

  public isParentSelected(productId: number) {
    return this.active.split('/')[0] == productId.toString();
  }

  public isNotSupplying(product: Product) {
    if (!this.isApp(this.PRODUCT_TYPE_MILK) || isPage('sites')) return false;
    return (
      (product.metrics as MilkMetrics).state &&
      (product.metrics as MilkMetrics).state != 'supply'
    );
  }

  public updateSorting(sortBy: TableColumnFormatters) {
    if (this.sortInfo.sortBy) {
      if (sortBy.name == this.sortInfo.sortBy?.name) {
        this.$emit('sorting-changed', {
          sortBy: sortBy,
          increasing: !this.sortInfo.increasing
        });
      } else {
        this.$emit('sorting-changed', {
          sortBy: sortBy,
          increasing: true
        });
      }
    }
  }
}
