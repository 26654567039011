
import { milkGlobals } from '@/store/Globals';
import { Product } from '@/store/models/Product';
import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';
import FixedProgressBar from '../items/FixedProgressBar.vue';
import VolumeProgressBar from '../items/VolumeProgressBar.vue';
import AppName from '../mixin/AppName.vue';
import ConstantsMixin from '../mixin/Constants.vue';

@Component({
  components: {
    FixedProgressBar,
    VolumeProgressBar
  },
  computed: {
    getMaxVatVolume() {
      return milkGlobals.maxVolume;
    }
  }
})
export default class VolumePercentBar extends mixins(AppName, ConstantsMixin) {
  @Prop() entity!: Product;
}
